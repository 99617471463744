import {BrowserRouter as Router} from "react-router-dom";
import AllRoutes from "./App/Routes/Routes";
import TransitionWrapper from "./App/Components/TransitionWrapper";
import {AppContextProvider} from "./App/Common/Context/AppContext";
import Vectors from "./App/Common/Vectors";
import Header from "./App/Common/Header";
import Footer from "./App/Common/Footer";

function App() {
  return (<div className="app">
    <AppContextProvider>
      <Header/>
      <ContentWrapper>
        <FormContentWrapper>
          <Router>
            <TransitionWrapper>
              <AllRoutes/>
            </TransitionWrapper>
          </Router>
        </FormContentWrapper>
        <Vectors/>
      </ContentWrapper>
      <Footer/>
    </AppContextProvider>
  </div>);
}

function ContentWrapper(props: { children: React.ReactNode }) {
  return (<div className="content-wrapper">
    {props.children}
  </div>)
}

function FormContentWrapper(props: { children: React.ReactNode }) {
  return (<div className="form-content-wrapper">
    {props.children}
  </div>)
}

export default App;
