import { FunctionComponent } from "react";

const ImageBasePath = "https://www.mysgi.ca/images";

const Vectors: FunctionComponent = () => {
  return (
    <div className="vectors-container" data-testid="vectors-test">
        <div className="vectors">
        <picture>
            <source
            media="(min-width: 1135px)"
            srcSet={`${ImageBasePath}/desktop_blue_vector.svg`}
            />
            <source
            media="(max-width: 1134px)"
            srcSet={`${ImageBasePath}/mobile_blue_vector.svg`}
            />
            <img src={`${ImageBasePath}/desktop_blue_vector.svg`} alt="" />
        </picture>
        <picture>
            <source
            media="(min-width: 1135px)"
            srcSet={`${ImageBasePath}/desktop_yellow_vector.svg`}
            />
            <source
            media="(max-width: 1134px)"
            srcSet={`${ImageBasePath}/mobile_yellow_vector.svg`}
            />
            <img src={`${ImageBasePath}/desktop_yellow_vector.svg`} alt="" />
        </picture>
        </div>
    </div>
  );
};

export default Vectors;
