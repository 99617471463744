import Banner from "./Banner";
import { GlobalHeaderv2 } from "@sgi/gravity/GlobalHeader";

export default function Header() { 
  return (
    <>
      <Banner data-testid="banner-test" />    
      <GlobalHeaderv2 
        isPublic={true} 
        isSignedIn={false} 
        data-testid="header-test" 
        className="header" 
      />
    </>
  );
}