import { BrokerIssuerBanner } from '@sgi/gravity/BrokerIssuerBanner'
import { FunctionComponent } from 'react'
import { useBanner } from '../Hooks/useBanner'

interface BannerProps {
  visibility?: boolean;
}
const Banner: FunctionComponent<BannerProps> = ({ visibility = true }) => {
  const { issuerId, brokerId } = useBanner()
  
  if (!visibility) return null;
  return (
    <div id="banner-container" data-testid="banner-container-test">
      {issuerId && <BrokerIssuerBanner issuerNumber={issuerId} />}
      {!issuerId && brokerId && <BrokerIssuerBanner brokerNumber={brokerId} />}
    </div>
  )
}

export default Banner;
